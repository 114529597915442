import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthRepositoryService } from './repositories/auth.repository.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorHandler } from './repositories/http-error-handler.service';
import { MessageService } from './repositories/message.service';
import { UsersRepositoryService } from './repositories/users.repository.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './services/storage.service';
import { CatchphrasesRepositoryService } from './repositories/catchphrases.repository.service';
import { TagsRepositoryService } from './repositories/tags.repository.service';
import { CatchphraseCommentsRepositoryService } from './repositories/catchphrase-comments.repository.service';
import { AuthService } from './services/auth.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AppService } from './services/app.service';
import { ConnectIdService } from './services/connect-id.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NotificationsService } from './services/notifications.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    /*    {
          provide: APP_INITIALIZER,
          useFactory: appInitializer,
          multi: true,
          deps: [AppService, AuthService, StorageService],
        },*/
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthRepositoryService,
    CatchphrasesRepositoryService,
    AppService,
    NotificationsService,
    AuthService,
    HttpErrorHandler,
    MessageService,
    UsersRepositoryService,
    StorageService,
    ConnectIdService,
    TagsRepositoryService,
    CatchphraseCommentsRepositoryService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
