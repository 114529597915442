import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateUserGoogleDto } from '../dto/users/create-user-google.dto';
import { UserDto } from '../dto/users/user.dto';
import { CatchphraseDto } from '../dto/catchphrases/catchphrase.dto';
import { ShortUserDto } from '../dto/users/short-user.dto';
import { SERVER_URL } from '../../environments/environment';
import { UpdateUserDto } from '../dto/users/update-user.dto';
import { UpdateUserPushAcceptedDto } from '../dto/users/update-user-push-accepted.dto';
import { CreateUserAppleDto } from '../dto/users/create-user-apple.dto';
@Injectable()
export class UsersRepositoryService {
  usersUrl = SERVER_URL + '/api/users/';  // URL to web api
  handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('AuthService');
  }

  createUserGoogle(user: CreateUserGoogleDto): Observable<UserDto> {
    return this.http.post<UserDto>(this.usersUrl + 'create/google', user);
  }

  createUserApple(user: CreateUserAppleDto): Observable<UserDto>  {
    return this.http.post<UserDto>(this.usersUrl + 'create/apple', user);
  }

  withUsernameExists(username: string): Observable<boolean> {
    return this.http.get<boolean>(this.usersUrl + 'username-exists/' + username);
  }

  withEmailExists(userEmail: string): Observable<boolean> {
    return this.http.get<boolean>(this.usersUrl + 'email-exists/' + userEmail);
  }

  getUser(username: string): Observable<ShortUserDto> {
    return this.http.get<ShortUserDto>(this.usersUrl + username);
  }

  updateUser(username: string, user: UpdateUserDto): Observable<UserDto> {
    const url = new URL(this.usersUrl + 'update/' + username);
    return this.http.put<UserDto>(url.toString(), user);
  }

  updateUserImage(username: string, file: File): Observable<UserDto> {
    const formData = new FormData();
    formData.append('profile-picture', file);

    return this.http.put<UserDto>(`${this.usersUrl}update-profile-picture/${username}`, formData);
  }

  getCatchphrasesLikedByUser(skip: number = 0): Observable<CatchphraseDto[]> {
    const url = new URL(this.usersUrl + 'liked-catchphrases');
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    url.search = urlSearchParams.toString();

    return this.http.get<CatchphraseDto[]>(url.toString());
  }

  followUser(username: string): Observable<ShortUserDto> {
    return this.http.post<ShortUserDto>(this.usersUrl + 'follow/' + username, {});
  }

  unfollowUser(username: string) {
    return this.http.post<ShortUserDto>(this.usersUrl + 'unfollow/' + username, {});
  }

  updateUserPushAccepted(username: string, updateUserPushAccepted: UpdateUserPushAcceptedDto): Observable<UserDto> {
    const url = new URL(this.usersUrl + 'update-user-push-accepted/' + username);
    return this.http.put<UserDto>(url.toString(), updateUserPushAccepted);
  }

  getUsersByRegex(skip: number = 0, user?: string, limit: number = 20): Observable<ShortUserDto[]> {
    const url = new URL(this.usersUrl + 'regex');
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    if (user && user.trim() !== '') {
      urlSearchParams.append('filter', user);
    }
    urlSearchParams.append('limit', String(limit));
    url.search = urlSearchParams.toString();
    return this.http.get<ShortUserDto[]>(url.toString());
  }

  getMultiple(skip: number = 0, usernames: string[], limit: number = 20): Observable<ShortUserDto[]> {
    const url = new URL(this.usersUrl + 'multiple');
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('skip', String(skip));
    usernames.forEach((username)=> {
      urlSearchParams.append('usernames', username);
    })
    urlSearchParams.append('limit', String(limit));
    url.search = urlSearchParams.toString();
    return this.http.get<ShortUserDto[]>(url.toString());
  }

  withAppleUserIdExists(appleUserId: string): Observable<boolean> {
    return this.http.get<boolean>(this.usersUrl + 'apple-user-id-exists/' + appleUserId);
  }

  deleteUser(username: string): Observable<boolean> {
    return this.http.delete<boolean>(this.usersUrl + username);
  }

  blockUser(username: string): Observable<UserDto> {
    return this.http.post<UserDto>(this.usersUrl + 'block/' + username, {});
  }

  unblockUser(username: string): Observable<UserDto> {
    return this.http.post<UserDto>(this.usersUrl + 'unblock/' + username, {});
  }

  hideCatchphrase(catchphraseId: string) {
    return this.http.post<boolean>(this.usersUrl + 'hide-catchphrase/' + catchphraseId, {});
  }

  unhideCatchphrases() {
    return this.http.post<boolean>(this.usersUrl + 'unhide-catchphrases', {});
  }

  updateShowHot(username: string, showHot: boolean) {
    const url = new URL(this.usersUrl + 'update-user-show-hot/' + username);
    return this.http.put<boolean>(url.toString(), { 'showHot':showHot });
  }
}
