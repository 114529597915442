import { Injectable } from '@angular/core';

@Injectable()
export class ConnectIdService {
  private _googleTokenId: string;
  private _appleTokenId: string;
  private _email: string;

  constructor() {
  }

  set googleTokenId(value: string) {
    this._googleTokenId = value;
  }

  get googleTokenId(): string {
    return this._googleTokenId;
  }

  get appleTokenId(): string {
    return this._appleTokenId;
  }

  set appleTokenId(value: string) {
    this._appleTokenId = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }
}
