import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { UserDto } from '../dto/users/user.dto';
import { AuthRepositoryService } from '../repositories/auth.repository.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggedUserDto } from '../dto/auth/logged-user.dto';
import { LoginUserGoogleDto } from '../dto/auth/login-user-google.dto';
import { map, take } from 'rxjs/operators';
import { ShortUserDto } from '../dto/users/short-user.dto';
import { UsersRepositoryService } from '../repositories/users.repository.service';
import { CreateUserGoogleDto } from '../dto/users/create-user-google.dto';
import { UpdateUserDto } from '../dto/users/update-user.dto';
import { CreateUserEmailDto } from '../dto/users/create-user-email.dto';
import { LoginUserEmailDto } from '../dto/auth/login-user-email.dto';
import { SendResetPasswordDto } from '../dto/auth/send-reset-password.dto';
import { ResetPasswordDto } from '../dto/auth/reset-password.dto';
import { UpdatePasswordDto } from '../dto/auth/update-password.dto';
import { UpdatePushTokenDto } from '../dto/auth/update-push-token.dto';
import { UpdateUserPushAcceptedDto } from '../dto/users/update-user-push-accepted.dto';
import { LoginUserAppleDto } from '../dto/auth/login-user-apple.dto';

@Injectable()
export class AuthService {
  public user: Observable<UserDto>;
  private userSubject: BehaviorSubject<LoggedUserDto>;
  private _authenticationRetried = false;
  private refreshTokenTimeout;
  private _isLogging = false;


  constructor(private storageService: StorageService,
              private usersRepo: UsersRepositoryService,
              private authRepo: AuthRepositoryService) {
    this.userSubject = new BehaviorSubject<LoggedUserDto>(new LoggedUserDto());
    this.user = this.userSubject.asObservable().pipe(map(source => source.user));
  }

  get authenticationRetried(): boolean {
    return this._authenticationRetried;
  }

  set authenticationRetried(value: boolean) {
    this._authenticationRetried = value;
  }

  public set isLogging(value: boolean) {
    this._isLogging = value;
  }

  public emit() {
    this.userSubject.next(this.userSubject.value);
  }

  public get isLogging(): boolean {
    return this._isLogging;
  }

  public get refreshTokenValue(): string {
    return this.userSubject.value?.refreshToken;
  }

  public get accessTokenValue(): string {
    return this.userSubject.value?.accessToken;
  }

  public get userValue(): UserDto {
    return this.userSubject.value?.user;
  }

  registerUserEmail(createUserEmail: CreateUserEmailDto): Observable<{ result: boolean }> {
    return this.authRepo.registerUserEmail(createUserEmail);
  }

  registerUserGoogle(createUserGoogle: CreateUserGoogleDto): Observable<UserDto> {
    return this.usersRepo.createUserGoogle(createUserGoogle);
  }

  loginUserGoogle(user: LoginUserGoogleDto): Observable<ShortUserDto> {
    return this.authRepo.loginGoogle(user).pipe(map(loggedUser => {
      this.userSubject.next(loggedUser);
      this.storageService.set('refreshToken', loggedUser.refreshToken).then();
      this.startRefreshTokenTimer();
      return loggedUser.user;
    }));
  }

  loginUserApple(user: LoginUserAppleDto): Observable<ShortUserDto> {
    return this.authRepo.loginApple(user).pipe(map(loggedUser => {
      this.userSubject.next(loggedUser);
      this.storageService.set('refreshToken', loggedUser.refreshToken).then();
      this.startRefreshTokenTimer();
      return loggedUser.user;
    }));
  }

  loginUserEmail(loginUserEmail: LoginUserEmailDto): Observable<UserDto> {
    return this.authRepo.loginEmail(loginUserEmail).pipe(map(loggedUser => {
      this.userSubject.next(loggedUser);
      this.storageService.set('refreshToken', loggedUser.refreshToken).then();
      this.startRefreshTokenTimer();
      return loggedUser.user;
    }));
  }

  login(): Observable<ShortUserDto> {
    return this.authRepo.login().pipe(map(user => {
      this.isLogging = false;
      const newUserLogged = this.userSubject.value;
      newUserLogged.user = user;
      this.userSubject.next(newUserLogged);
      return newUserLogged.user;
    }));
  }

  async logout() {
    this.stopRefreshTokenTimer();
    await this.storageService.remove('refreshToken').then();
    this.userSubject.next(new LoggedUserDto());
  }

  refreshToken(refreshToken: string): Observable<string> {
    return this.authRepo.refreshToken(refreshToken)
      .pipe(map((accessToken) => {
        this.userSubject.value.accessToken = accessToken.accessToken;
        this.startRefreshTokenTimer();
        if (!this.userSubject.value.refreshToken) {
          this.userSubject.value.refreshToken = refreshToken;
        }
        return accessToken.accessToken;
      }));
  }

  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public updateUser(username: string, updateUserDto: UpdateUserDto): Observable<UserDto> {
    return this.usersRepo.updateUser(username, updateUserDto).pipe(map(res => {
      if (res) {
        const newUserLogged = this.userSubject.value;
        newUserLogged.user = res;
        this.userSubject.next(newUserLogged);
        return res;
      }
    }));
  }

  public updatePushAccepted(updateUserPushAcceptedDto: UpdateUserPushAcceptedDto): Observable<UserDto> {
    return this.usersRepo.updateUserPushAccepted(this.userValue.username, updateUserPushAcceptedDto).pipe(map(res => {
      if (res) {
        const newUserLogged = this.userSubject.value;
        newUserLogged.user = res;
        this.userSubject.next(newUserLogged);
        return res;
      }
    }));
  }

  public updateShowHot(showHot: boolean): Observable<boolean> {
    return this.usersRepo.updateShowHot(this.userValue.username, showHot).pipe(map(res => {
      if (res) {
        const newUserLogged = this.userSubject.value;
        newUserLogged.user.showHot = showHot;
        this.userSubject.next(newUserLogged);
        return res;
      }
    }));
  }

  public updateUserImage(file: File): Observable<UserDto> {
    return this.usersRepo.updateUserImage(this.userValue.username, file).pipe(map(res => {
      if (res) {
        const newUserLogged = this.userSubject.value;
        newUserLogged.user = res;
        this.userSubject.next(newUserLogged);
        return res;
      }
    }));
  }

  public hasLikedCatchphrase(catchphraseId: string): Observable<boolean> {
    return new Observable(observer => {
      this.user.subscribe(user => {
        if (user) {
          observer.next(user.likedCatchphrases.includes(catchphraseId));
        }
      });
    });
  }

  public addLikedCatchphrase(catchphraseId: string) {
    const newUserLogged = this.userSubject.value;
    newUserLogged.user.likedCatchphrases.push(catchphraseId);
    this.userSubject.next(newUserLogged);
  }

  public removeLikedCatchphrase(catchphraseId: string) {
    const newUserLogged = this.userSubject.value;
    newUserLogged.user.likedCatchphrases
      .splice(newUserLogged.user.likedCatchphrases.indexOf(catchphraseId), 1);
    this.userSubject.next(newUserLogged);
  }

  public hasFollowedUser(userId: string): Observable<boolean> {
    return new Observable(observer => {
      this.user.subscribe(user => {
        if (user) {
          observer.next(user.followedUsers.includes(userId));
        }
      });
    });
  }

  sendResetPassword(resetPassword: SendResetPasswordDto): Observable<{ result: boolean }> {
    return this.authRepo.sendResetPassword(resetPassword);
  }

  resetPassword(resetPassword: ResetPasswordDto): Observable<{ result: boolean }> {
    return this.authRepo.resetPassword(resetPassword);
  }

  updatePassword(updatePassword: UpdatePasswordDto): Observable<{ result: boolean }> {
    return this.authRepo.updatePassword(updatePassword).pipe(map(res => {
      if (res) {
        const newUserLogged = this.userSubject.value;
        newUserLogged.refreshToken = res.refreshToken;
        this.userSubject.next(newUserLogged);
        this.storageService.set('refreshToken', newUserLogged.refreshToken).then();
        this.stopRefreshTokenTimer();
        this.startRefreshTokenTimer();
        return { result: true };
      }
    }));
  }

  updatePushTokenDto(updatePushToken: UpdatePushTokenDto): Observable<{ result: boolean }> {
    return this.authRepo.updatePushTokenDto(updatePushToken);
  }

  public addFollowedUser(username: string) {
    const newUserLogged = this.userSubject.value;
    newUserLogged.user.followedUsers.push(username);
    this.userSubject.next(newUserLogged);
  }

  removeFollowedUser(username: string) {
    const newUserLogged = this.userSubject.value;
    newUserLogged.user.followedUsers
      .splice(newUserLogged.user.followedUsers.indexOf(username), 1);
    this.userSubject.next(newUserLogged);
  }

  public addBlockedUser(username: string) {
    const newUserLogged = this.userSubject.value;
    newUserLogged.user.blockedUsers.push(username);
    this.userSubject.next(newUserLogged);
  }

  removeBlockedUser(username: string) {
    const newUserLogged = this.userSubject.value;
    newUserLogged.user.blockedUsers
      .splice(newUserLogged.user.blockedUsers.indexOf(username), 1);
    this.userSubject.next(newUserLogged);
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.accessTokenValue.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(this.refreshTokenValue)
      .pipe(take(1)).subscribe(), timeout);
  }
}
