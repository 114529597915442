import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { first, take } from 'rxjs/operators';
import { NotificationsService } from './services/notifications.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { FRONT_DOMAIN } from '../environments/environment';
import { Network } from '@capacitor/network';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private storageSubscription: Subscription;

  constructor(private platform: Platform,
              public authenticationService: AuthService,
              public notificationsService: NotificationsService,
              public storageService: StorageService,
              private router: Router,
              private navCtrl: NavController,
              private zone: NgZone) {
    this.authenticationService.isLogging = true;

    this.initDeeplink();
    this.notificationsService.initPush().then();
    if (!this.platform.is('hybrid')) {
      GoogleAuth.initialize();
    } else {
      this.platform.backButton.subscribeWithPriority(0, () => {
        if (window.location.pathname === '/home' || window.location.pathname === '/login') {
          navigator['app'].exitApp();
        }
      });
    }
  }


  async ngOnInit() {
    this.storageSubscription = this.storageService.storage.subscribe(async res => {
      if (res === 'created') {
        if ((await Network.getStatus()).connected) {
          await this.initUser();
        } else {
          const navOptions = {
            animated: false,
          };
          await this.navCtrl.navigateForward(['network-error'], navOptions);
        }
        Network.addListener('networkStatusChange', async status => {
          if (status.connected) {
            if (this.router.url.includes('network-error')) {
              await this.navCtrl.navigateRoot(['home']);
            }
            if (!this.authenticationService.userValue) {
              await this.initUser();
            }
          } else {
            const navOptions = {
              animated: false,
            };
            await this.navCtrl.navigateForward(['network-error'], navOptions);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.storageSubscription.unsubscribe();
  }

  private async initUser() {
    const refreshToken: string = await this.storageService.get('refreshToken');
    if (refreshToken) {
      this.authenticationService.isLogging = true;
      // attempt to refresh token on app start up to auto authenticate
      await this.authenticationService.refreshToken(refreshToken).pipe(first()).toPromise().catch(err => {
        if (err.status !== 0 && err.status !== 504) {
          this.authenticationService.isLogging = false;
        }
        return;
      });
      this.authenticationService.login().pipe(take(1)).subscribe();
    } else {
      this.authenticationService.isLogging = false;
      this.authenticationService.emit();
    }
  }

  private initDeeplink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(FRONT_DOMAIN).pop();
        if (slug) {
          this.router.navigateByUrl(slug).then();
        }
      });
    });
  }
}
